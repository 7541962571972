import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { CommonService } from 'src/app/services/common.service';
import { BehaviorSubject, Subject, } from 'rxjs';
import { Subscription } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { AdminService } from '../admin-service';
import { InvoiceService } from '../invoice.service';
import { MatDialog } from '@angular/material/dialog';
import { WarningDialogComponent } from 'src/app/shared/components/warning-dialog/warning-dialog.component';
import { tokenWrapper } from 'src/app/models/tokenwrapper.model';
// import { DeviceDetectorService } from 'ngx-device-detector';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
@Injectable({ providedIn: 'root' })
export class AuthManagerService  {
  userModules = [];
  userSubModules = [];
  accessToken: string;
  idToken: string;
  tokenType: string;
  sessionTimeout: number;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public initiateAddInvoiceCancel = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient, private router: Router, private authService: AuthService,
    private commonService: CommonService, private adminService: AdminService, private iS:InvoiceService,public dialog: MatDialog) { // , private deviceService: DeviceDetectorService
    this.accessToken = this.getUrlVars()['access_token'];
    this.tokenType = this.getUrlVars()['token_type'];
    this.idToken = this.getUrlVars()['id_token'];
    this.sessionTimeout = this.getUrlVars()['expires_in'];
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let checkIfAddInvoice:boolean;
    this.iS.addInvoiceTempFlag.subscribe(res => checkIfAddInvoice = res ? true : false);

    if (localStorage.getItem('access_token')) {
      this.commonService.accessTokenReceived.next(true);
      this.accessToken = localStorage.getItem('access_token');
      if (this.accessToken && localStorage.getItem('paceUserData')) {
        let res = JSON.parse(localStorage.getItem('paceUserData'));
        this.getUserAccessAll(res);
        this.setUserIdInSession(res);
        this.loggedIn.next(true);
        //MAFN-238
        if( checkIfAddInvoice ){
          const dialogRef = this.dialog.open(WarningDialogComponent, {
            width: '372px',
            height: 'auto',
            disableClose: true,
            data: { name: 'cancel' }
          });
          dialogRef.afterClosed().subscribe(resp => {
            if ( resp ) {
              if( resp.reset ){
                this.iS.addInvoiceTempFlag.next(false);
                this.initiateAddInvoiceCancel.next(true);
                this.router.navigateByUrl('/' + state.url);
              }
            }
            return false;
          });
          return false;
        }
        if (this.hasAnymoduleAccess()) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (this.accessToken) {
        return this.commonService.getUserProfile(this.accessToken).pipe(map(res => {
          this.getUserAccessAll(res);
          this.setUserIdInSession(res);
          this.loggedIn.next(true);
          if (this.hasAnymoduleAccess()) {
            return true;
          }
          else {
            return false;
          }
        }
      ), catchError(e =>this.errorToken(e)));
      }
      else {
        localStorage.clear();
        this.navigateToSSOpage();
        return false;
      }
    }
    else if (this.accessToken !== null && this.accessToken !== undefined) {
      localStorage.setItem('access_token', this.accessToken);
      this.commonService.accessTokenReceived.next(true);
      localStorage.setItem('id_token', this.idToken);
      this.setTimeoutValInSession();
      return this.commonService.getUserProfile(this.accessToken).pipe(map((res: any) => {
        this.getUserAccessAll(res);
        this.setUserIdInSession(res);
        newrelic.setCustomAttribute('UserSSO',localStorage.getItem('paceUserSSO'));
        this.navigateToReferenceUrl(res);
        this.loggedIn.next(false);
        return false;
      }
      ), catchError(e =>this.errorToken(e)));
    }
    else if (!localStorage.getItem('access_token')) {
      this.navigateToSSOpage();
      this.loggedIn.next(false);
      return false;
    }
  }

  setTimeoutValInSession(){
    var t = new Date();
    t.setSeconds(t.getSeconds() + Number(this.sessionTimeout) - 10);
    localStorage.setItem('timeout', t.toString());
  }

  setUserIdInSession(res){
    if (res['userDetails'] && res['userDetails']['user']) {
      if (res['userDetails']['user'].userIdNo) {
        localStorage.setItem('paceUserSSO', res['userDetails']['user'].userIdNo);
      }
    }
  }

  hasAnymoduleAccess() {
    if (this.userModules && this.userModules.length > 0) {
      return true;
    }
    return false;
  }

  navigateToReferenceUrl(res){
    if(this.userModules && this.userModules.length> 0){
      if( res.defaultModuleName && this.userModules.indexOf(res.defaultModuleName.toLowerCase()) >-1 ){
        this.router.navigateByUrl('/' + res.defaultModuleName.toLowerCase());
      }else{
        this.router.navigateByUrl('/' + this.userModules[0]);
      }
    }
    else{
      this.router.navigate(['./']);
    }
  }

  getUrlVars() {
    var vars = [];
    var hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('#') + 1).split('&');
    hashes.forEach(element => {
      hash = element.split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    });
    return vars;
  }

  errorToken(error: HttpErrorResponse) {
    if (error.error) {
    if(error.error.errorCode === 'E0001' || error.error.errorCode === 'M_E0001')
    {
      this.router.navigateByUrl('/error');
      this.authService.loggedInValidation =error.error.errorDesc;
    }
    else if(error.error.errorCode === 'E0003')
    {
      localStorage.removeItem('access_token');
      localStorage.removeItem('paceUserSSO');
      this.authService.loggedInValidation =error.error.errorDesc;
      this.router.navigateByUrl('/error');
    }
    else
    {
      localStorage.removeItem('access_token');
      localStorage.removeItem('paceUserSSO');
      this.navigateToSSOpage();

    }
  }

    return null;
  }

  relogin(){
    // if(environment.name!=="Local") newrelic.addPageAction("SessionTimedOut",
    // {"expiredAt": (new Date(localStorage.getItem('timeout'))).toString()});
    localStorage.clear();
    this.navigateToSSOpage();
  }

  navigateToSSOpage() {
    if (window.location.href.indexOf('#access_token=') < 0) {
      localStorage.setItem('referenceUrl', window.location.href.replace(environment.appURL, ''));
    }
    window.location.replace('https://' + environment.authURL + '/as/authorization.oauth2?client_id=' + environment.clientId + '&response_type=token id_token&redirect_uri=' + environment.appURL + '&scope=' + environment.scope);
  }

  getUserAccessAll(userAccess){
    this.userModules = [];
    this.userSubModules = [];
    if (userAccess && userAccess.userEntitlements && userAccess.userEntitlements.length > 0){
      this.userModules = userAccess.userEntitlements.map(a => a.moduleName.toLowerCase());
      this.sortModulesByDisplayOrder(this.userModules);
      this.adminService.getUserAccessAll().subscribe((res: any) => {
        this.formUserAccessObject(res, userAccess);
      });
    }
    else{
      userAccess['userTabs'] = null;
      userAccess['userSubTabs'] = null;
      localStorage.setItem('paceUserData', JSON.stringify(userAccess));
      this.commonService.userData.next(userAccess);
    }
  }

  sortModulesByDisplayOrder(arr) {
    let links = ['estimates', 'campaigns', 'invoices', 'reports', 'accruals', 'admin'];;
    arr.sort(function (a, b) {
      return links.indexOf(a)
        - links.indexOf(b);
    });
  }

  formUserAccessObject(userAccessAll, userAccess) {
    let loggedinUserAccess = {};
    if (userAccessAll && userAccessAll.length > 0) {
      userAccessAll.forEach(module => {
        let hasModuleAccess = userAccess.userEntitlements.find(obj => obj.moduleName === module.moduleName);
        if (hasModuleAccess && hasModuleAccess.rolesModuleEntitlement && hasModuleAccess.rolesModuleEntitlement.length > 0){
          module.access.forEach(action => {
            let userModuleAction = hasModuleAccess.rolesModuleEntitlement.find(obj => obj.entitlementName === action.entitlementName);
            if (userModuleAction) {
              loggedinUserAccess[action.property] = true;
              if (action.subTab){
                this.formUserSubModules(action);
              }
            }
            else {
              loggedinUserAccess[action.property] = false;
            }
          });
        }
        else{
          this.setModuleActionsFalse(loggedinUserAccess, module);
        }
      });
    }
    userAccess['userTabs'] = this.userModules;
    userAccess['userSubTabs'] = this.userSubModules;
    localStorage.setItem('paceUserData', JSON.stringify(userAccess));
    userAccess['userAccessObj'] = loggedinUserAccess;

    this.commonService.userData.next(userAccess);
  }

  setModuleActionsFalse(userAccessObj, moduleObj){
    moduleObj.access.forEach(action => {
      userAccessObj[action.property] = false;
    });
  }

  formUserSubModules(access) {
    const existInList = access.subTab[0];
    if (this.userSubModules.length === 0 || (this.userSubModules.length > 0 && this.userSubModules.indexOf(existInList) < 0)) {
      this.userSubModules = this.userSubModules.concat(access.subTab);
    }
  }
}
